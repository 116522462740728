.strip {
    background-size: cover;
    background-position: center;
    margin-top: 40px;
    margin-bottom: -97px;
    position: relative;
}

.img {
    width: 354px;
    position: relative;
    right: 0;
    top: -56px;
    z-index: 3;

}

.img_wrap {
    position: relative;
    height: 180px;
}

.bestFood {
    background-size: contain;
    z-index: 2;
    position: relative;
    padding-top: 149px;
    width: 100%;
    overflow: hidden;
}

.num {
    color: #0D7A52;
    font-weight: bold;
    font-size: 40px;
}

.title {
    color: #8DCA57;
}

.info {
    font-size: 13px;
}

._50perc {
    color: #F9DA31;
    font-size: 40px;
    font-weight: bold;
}

.off {
    font-size: 40px
}

.shop {
    font-size: 25px;
    font-weight: bold;
}

.popul {
    font-size: 26px;
    text-align: left;
    font-weight: bold;
}

.blog_desc {
    font-size: 12px
}

.moreBtn {
    background-color: #8DCA57
}

.newsletter {
    border-radius: 8px;
    margin: auto;
    max-width: 850px
}

.ifn {
    color: #0E7A52;
    font-size: 12px;
}

.newsletter_wrapper {
    background-color: #0E7A52;
    max-width: 650px;
    margin: auto;
}

.newsLetterBtn {
    background-color: #8DCA57;
}

.input_news_letter {
    background-color: #0E7A52;
    border: medium none;
}

.input_news_letter:focus-visible {
    outline: none;
}

.newsl {
    background-size: 100%;
    background-position: center bottom;
    margin-bottom: -93px;
}

.footer_title {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.footer_text {
    font-size: 12px
}

.footerTop {
    padding-top: 140px;
    position: relative;
    background-position: center top;
    background-size: cover;
    padding-bottom: 30px
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .bestFood {
        background-size: cover;
        background-repeat: no-repeat;

    }

}

@media only screen and (max-width: 767px) {
    .bestFood::before {
        content: "";
        background: #F8F7F3;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 90%;
    } 
    .strip { padding: 20px}

    .img {
        width: 106%;
        top: -30px;
    }

}

@media only screen and (max-width: 767px) and (orientation: landscape) {
    .strip {
        padding: 20px
    }
    .img {
        width: 40% !important;
        right: 0;
        top: -12px;
    }
}
.product_card {
  border: 1px solid rgba(112, 112, 112, 15%);
  box-shadow: 0 6px 6px rgba(0, 0, 0, 5%);
  border-radius: 5px;
}

.btn_add_to_cart {
  background-color: rgba(240, 240, 240, 100%);
}

.btn_text_small {
  font-size: 11px;
}

.qty {
  background-color: #e3e3e3;
  border: 1px solid #e3e3e3;
}

.tab {
  border-bottom: 3px solid #fff;
}

.activeTab {
  border-bottom: 3px solid #8dca57 !important;
}
.tabs_wrapper {
  overflow-x: scroll;
  width: 100%;
}

:is(.new, .off) {
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  padding: 3px;
}

.new {
  background-color: #8dca57;
}

.off {
  background-color: #fe4848;
}

.overlay_pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
}

.alert_inner {
  max-width: 300px;
  width: 100%;
}

.close_btn {
  position: absolute;
  right: -8px;
  top: -6px;
  box-shadow: 0 0 6px #00000078;
  border-radius: 50%;
  background-color: #fff;
  font-size: 4px;
  padding: 2px;
}

.alert_title {
  font-weight: 600;
  font-size: 23px;
}

.header {
  background-color: #25441b;
  color: #fff;
}

.logo img {
  border: 0.5px solid #fff;
  border-radius: 7px;
}

.container {
  max-width: 1120px;
  width: 100%;
  margin: auto;
}

.nav_search {
  background-color: #fff;
  border-radius: 700px;
  padding: 6px;
}

.btnSearch {
  background-color: #ff9c00;
  border-radius: 50%;
  padding: 2px;
}

.input {
  width: 100%;
  border: medium none;
}

.input:focus-visible {
  outline: none;
}

.contact {
  background-color: #fff;
  color: #25441b;
  border-radius: 20px;
  padding: 2px 10px;
}

.callBtn {
  color: #fff;
  background-color: #25441b;
  border-radius: 50%;
  padding: 6px;
  font-size: 15px;
}

.call_ {
  font-size: 10px;
  font-weight: bold;
}

.call {
  font-size: 13px;
  font-weight: bold;
}

.cart_ {
  color: #25441b;
  background-color: #fff;
  border-radius: 50%;
  font-size: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.count {
  background-color: #ff9c00;
  position: absolute;
  font-size: 12px;
  right: -10px;
  height: 15px;
  width: 15px;
}

.catx {
  font-size: 12px;
}

.user_dropdown {
  position: absolute;
  top: 10px;
  opacity: 0;
  padding: 1px;
  background-color: #fff;
  min-width: 250px;
  right: 0;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  border-radius: 5px;
  transition: top 0.3s ease-in-out;
  visibility: hidden;
  z-index: 6;
}

.user_dropdown.show {
  top: 40px;
  opacity: 1;
  transition: top 0.3s ease-in-out;
  height: auto;
  visibility: visible;
}

.dropdownItems {
  font-size: 12px;
}

.cemail {
  font-size: 11px;
  opacity: 0.8;
}
.cphone {
  font-weight: 600;
  font-size: 12px;
  opacity: 0.7;
}

@media only screen and (max-width: 767px) {
  .cart_ {
    width: 35px;
    height: 35px;
  }
  .nav_search {
    padding: 2px;
  }
}

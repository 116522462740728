:root{
  --orange : #FF9C00;
  --primary:#25441B;
  --secondary:#FDE105;
  --accent: #8DCA57;
}

body {
  font-family: "Poppins";
}
.body_gray {
  background: #e5e6ea;
}
h1,
h2,
h3,
h4 {
  font-weight: bold;
  color: #25441b;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
}
.main-menu {
  z-index: 100;
}

.container {
  max-width: 1120px;
  width: 100%;
  margin: auto;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bg-green {
  background-color: #25441b;
  color: #fff;
}
.bg_lime {
  background: #8dca57;
  color: #fff;
}
.bg_lime_dark {
  background: #82a284;
  color: #fff;
}

.slider {
  width: 100%;
  overflow: hidden;
}
.hide-input {
  display: none;
}

.bg-success {
  background-color: #8dca57 !important;
}

.overlay {
  background-color: #25441b55;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.pointer {
  cursor: pointer;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.react-tel-input .form-control {
  width: 100% !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

.App-link {
  color: #61dafb;
}
.btn-primary {
  color: #fff;
  background-color: #8dca57 !important;
  border-color: #8dca57 !important;
}
ul.country-list {
  text-align: left;
}

.dropdown-divider {
  width: 100%;
  height: 1px;
  background-color: #00000003;
  opacity: 0.4;
}

.usr_drop .material-icons-outlined {
  font-size: 17px;
}
.usr_ a.active,
.usr_ a:hover {
  color: #8dca57 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.track_action .material-icons-outlined {
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  padding: 2px;
  font-size: 18px;
}
.ac_header {
  /* font-size: 13px; */
  font-weight: bold;
}

.ac_status {
  border-radius: 2px;
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  padding: 2px 7px;
  font-weight: 500;
}
.ac_date {
  font-size: 12px;
}

.line_track {
  position: absolute;
  width: 4px;
  height: 96%;
  left: 9.5px;
  top: 24px;
}

.side_menu {
  padding: 10px 15px;
}
.active .side_menu {
  background-color: #ededef;
}
.active .item-0 {
  border-radius: 0.25rem 0.25rem 0 0;
}
.side_menu:hover {
  background-color: #dedee1ed;
}

.form-check-input:checked {
  background-color: #8dca57;
  border-color: #8dca57;
}

.slider-control-centerright button,
.slider-control-centerleft button {
  display: flex;
  opacity: 0.2 !important;
}
.slider-control-centerright button:hover,
.slider-control-centerleft button:hover {
  opacity: 1 !important;
}

.floating_cart {
  position: fixed;
  right: 0;
  top: 20%;
  background: #fe4848;
  color: #fff;
  padding: 10px;
  z-index: 5;
  border-radius: 5px 0 0 5px;
  box-shadow: 0 0 6px rgb(0 0 0 / 30%);
}

.floating_cart .material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 200, "GRAD" 200, "opsz" 48;
}

.search-drop {
  position: absolute;
  bottom: -60%;
  width: 100%;
  z-index: 1;
  background: #356227;
  padding: 10px 0;
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.search-drop::after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #356227;
  top: -8px;
  right: 3%;
  position: absolute;
}
.search-drop.show {
  visibility: visible;
  height: auto;
  opacity: 1;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.line-through {
  position: relative;
}
.line-through > span {
  z-index: 1;
}
.line-through::after {
  content: "";
  background-color: #00000024;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  position: absolute;
}
.login_social img {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #00000014;
  border-radius: 50%;
  margin: 10px;
}

.faq_ans {
  height: 0;
  position: relative;
  opacity: 0;
  transition: height 0.3s ease-in-out, opacity 0.4s ease-in-out;
}

.qcollapsed .faq_ans {
  height: auto !important;
  opacity: 1;
  padding-top:10px;
  margin-top:10px;
  border-top:1px solid #dee2e6
}

.qtab {
    border: 1px solid #dee2e6;
    border-radius: 6px;
    box-shadow: 0 0 10px 3px #00000012;
}

.col-xs-6 {
    flex: 0 0 auto;
    width: 50%;
}

.socials img{ margin: 10px; opacity:.8}

.socials img:hover{opacity: .5}


.sign_tabs span{
    font-weight: 800;
    color: #00000047;
    cursor:pointer;
}
.sign_tabs > span.active {
    color: var(--primary);
    border-bottom: 2px solid;
    border-color: var(--accent);
}

.icon_small {
    font-size: 18px !important;
}
.cart_wrapper {
  margin-top: 60px;
  /* box-shadow: 0px 0px 6px #000; */
  background: #fff;
}
.cart_table_wrapper {
  overflow-x: scroll;
  width: 100%;
}

.cart_sum {
  background: #f5f5f5;
}

.headers {
  font-weight: bold;
  border-bottom: 1px solid #0000001a;
  padding-bottom: 10px;
}

.promoBtn {
  background: #f3726c;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
}

:is(table.cart_table thead th, table.cart_table thead, tbody, tbody td) {
  border: medium none !important;
}

table.cart_table tbody tr::after {
  content: "";
  background: #000 !important;
  width: 100%;
  height: 1px;
  position: relative;
}

.back_link:hover {
  color: #8dca57;
}
.btn_cart {
  font-size: 13px;
  background: #f5f5f5;
  padding: 5px;
}

.p_card {
  border: 1px solid #00000030;
  border-radius: 2px;
}
.p_card_active {
  background-color: #8dca57;
  color: #fff;
  border-color: #25441b2f;
}

.phone_number_wrapper {
  background-color: #f5f5f596;
  /* color: #8DCA57;  */
}
.billing_address {
  background-color: #f5f5f5;
}

.billing_address > div {
  margin-bottom: 5px;
  font-size: 12px;
}

.card_Wrapper label {
  font-size: 14px;
  font-weight: 400;
}
.card_Wrapper input,
.card_Wrapper select {
  font-size: 14px;
}

/* .cardLogos{width:100%; height: 80px; background-size: 100%;} */
.hblock{
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 25px 0px rgb(0 0 0 / 10%);
    border-radius: 5px;
}
.hdec{
    font-size:12px;
}
.htitle{font-weight: 500; font-size: 15px; padding-bottom:5px; }

.hicon{
    font-size: 36px !important;
    color: #25441B;

  font-variation-settings:
  'FILL'0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 48
}

.social_button_wrapper{
  position:fixed;
  right:5%;
  top:20%

}
.social_button_wrapper_mobile { margin-top: 20px}